<template>
  <el-main class="rh-medform">
    <form-hcactivitecreate :routes="hcmRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormHcactivitecreate from "@/components/rh/formulaires/FormHcactivitecreate";

export default {
  components: { "form-hcactivitecreate": FormHcactivitecreate },
  computed: {
    hcmRoutes() {
      return {
        toedit: {
          name: "med_hcactivites_edit",
          params: {
            idmed: this.$route.params.idmed,
            idhcm: this.$store.state.hcm.hcactivitemedecinLastCreatedId,
          },
        },
        tolist: {
          name: "med_hcactivites",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
